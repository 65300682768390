/* global tw */
import React from "react";
import CardList from "../components/CardList";
import Card from "../components/Card";
import styled from "styled-components";
import { Link } from "gatsby";

const SectionTitle = styled.h2`
	${tw`text-4xl md:text-6xl font-dincond font-black border-black border-b-4 text-black uppercase mb-4 leading-mini`};
`;

// const CbWrapper = styled.div`
// 	${tw`cardboard p-4 md:p-8 md:m-6`};
// `;

const CardSelections = ({
	post,
	items,
	cardlistTitle,
	allLink,
	allName,
	...props
}) => {
	return (
		<div className="container">
			{cardlistTitle ? (
				<SectionTitle>
					{cardlistTitle}{" "}
					<span className="sm:pl-2 block sm:inline">
						<Link
							to={allLink}
							className="text-xl text-cb-red hover:text-red-dark trans"
						>
							{allName}
						</Link>
					</span>
				</SectionTitle>
			) : null}
			<CardList>
				{items.map((entry, i) => (
					<Card
						key={i}
						mainLink={`/${entry.uri}`}
						title={entry.title}
						avatarStyle={true}
						imageUrl={
							!entry.featuredImage.length
								? "https://placehold.it/400x225"
								: entry.featuredImage[0].url +
								  "?sat=-100&w=400&h=225&fit=crop&crop=entropy"
						}
						tinyImageUrl={
							!entry.featuredImage.length
								? "https://placehold.it/73x73"
								: entry.featuredImage[0].url +
								  "?w=73&h=73&fit=crop&crop=entropy"
						}
						catName={"cat here"}
					/>
				))}
			</CardList>
		</div>
	);
};

export default CardSelections;

// entry.locationCategory && entry.locationCategory.length
// ? entry.locationCategory[0].title
// : entry.companyIndustry && entry.companyIndustry.length
// ? entry.companyIndustry[0].title
// : entry.newsCategory && entry.newsCategory.length
// ? entry.newsCategory[0].title
// : null
