/* global tw */
import React from "react";
import styled from "styled-components";

const List = styled.div`
	${tw`flex flex-wrap sm:-mx-3 md:px-0 -mb-6`};
`;

const CardList = props => {
	return <List>{props.children}</List>;
};

export default CardList;
