/* global tw */
import React from "react";
import styled from "styled-components";

const FlexNone = styled.div`
	${tw`flex-none`};
`;

const PageHeading = ({ post, title, imageUrl, ...props }) => {
	return (
		<FlexNone>
			{imageUrl ? (
				<img src={imageUrl} alt={title} className="img-fluid w-full" />
			) : null}
		</FlexNone>
	);
};

export default PageHeading;
