/* global tw */
import React from "react";
import styled from "styled-components";
import {
	FaDribbble,
	FaFacebook,
	FaFoursquare,
	FaGithub,
	FaInstagram,
	FaLinkedinIn,
	FaMediumM,
	FaPinterest,
	FaSnapchat,
	FaSoundcloud,
	FaTumblr,
	FaTwitter,
	FaVimeo,
	FaYelp,
	FaYoutube
} from "react-icons/fa";

const SocialNetworkList = styled.div`
	${tw`block`};
`;

const ItemList = styled.ul`
	${tw`list-reset`};
`;

const Item = styled.li`
	${tw`flex justify-center`};
	transition: 0.4s all;
	transform: scale(1);
	&:hover {
		transform: scale(1);
	}
	&:hover span {
		transform: scale(1.4);
	}
`;

// .side-actioner ul li:hover [class^="icon-"], .side-actioner ul li:hover [class*=" icon-"]

const IconHolder = styled.span`
	${tw`ml-1`};
	transform: scale(1);
	transition: 0.4s all;
`;

// const Item = styled.li`
// 	${tw`text-2xl uppercase font-dincond font-bold m-0 overflow-x-hidden pt-3 pr-0 pb-2 pl-0 border-b border-black flex items-center`};
// `;

// const MiniItem = styled.li`
// 	${tw`text-2xl uppercase font-dincond font-bold m-0 overflow-x-hidden`};
// `;

const ItemLink = styled.a`
	${tw`uppercase text-black`};
`;
// font-size: ${props => (props.featured ? "3rem" : ".5rem")};

const ItemName = styled.p`
	${tw`pl-4 text-cb-red mb-0`};
`;

const bigClasses =
	"text-2xl uppercase font-dincond font-bold m-0 overflow-x-hidden pt-3 pr-0 pb-2 pl-0 border-b border-black flex items-center w-full";

const miniClasses =
	"text-xl uppercase inline-flex leading-none pr-3 items-center";

const SocialNetworks = ({ sizing, featured, carpet, ...props }) => {
	return (
		<SocialNetworkList>
			{props.items.map((item, i) => (
				<SocialNetworkList key={i}>
					<ItemList className={props.mini ? "my-3 flex justify-start" : null}>
						{item.instagram ? (
							<Item featured={props.featured}>
								<ItemLink
									href={"https://instagram.com/" + item.instagram}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaInstagram />
									</IconHolder>
									{!props.mini ? <ItemName>Instagram</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.facebook ? (
							<Item>
								<ItemLink
									href={"https://facebook.com/" + item.facebook}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaFacebook />
									</IconHolder>
									{!props.mini ? <ItemName>Facebook</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.pinterest ? (
							<Item>
								<ItemLink
									href={"https://pinterest.com/" + item.pinterest}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaPinterest />
									</IconHolder>
									{!props.mini ? <ItemName>Pinterest</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.yelp ? (
							<Item>
								<ItemLink
									href={"https://yelp.com/biz/" + item.yelp}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaYelp />
									</IconHolder>
									{!props.mini ? <ItemName>Yelp</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.menupages ? (
							<Item>
								<ItemLink
									href={"https://menupages.com/" + item.menupages}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									{!props.mini ? <ItemName>MenuPages</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.vimeo ? (
							<Item>
								<ItemLink
									href={"https://vimeo.com/" + item.vimeo}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaVimeo />
									</IconHolder>
									{!props.mini ? <ItemName>Vimeo</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.youtube ? (
							<Item>
								<ItemLink
									href={"https://youtube.com/" + item.youtube}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaYoutube />
									</IconHolder>
									{!props.mini ? <ItemName>YouTube</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.medium ? (
							<Item>
								<ItemLink
									href={"https://medium.com/" + item.medium}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaMediumM />
									</IconHolder>
									{!props.mini ? <ItemName>Medium</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.twitter ? (
							<Item>
								<ItemLink
									href={"https://twitter.com/" + item.twitter}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaTwitter />
									</IconHolder>
									{!props.mini ? <ItemName>Twitter</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.dribbble ? (
							<Item>
								<ItemLink
									href={"https://dribbble.com/" + item.dribbble}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaDribbble />
									</IconHolder>
									{!props.mini ? <ItemName>Dribbble</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.foursquare ? (
							<Item>
								<ItemLink
									href={"https://foursquare.com/v/" + item.foursquare}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaFoursquare />
									</IconHolder>
									{!props.mini ? <ItemName>Foursquare</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.github ? (
							<Item>
								<ItemLink
									href={"https://github.com/" + item.github}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaGithub />
									</IconHolder>
									{!props.mini ? <ItemName>GitHub</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.linkedin ? (
							<Item>
								<ItemLink
									href={"https://www.linkedin.com/company/" + item.linkedin}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaLinkedinIn />
									</IconHolder>
									{!props.mini ? <ItemName>LinkedIn</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.snapchat ? (
							<Item>
								<ItemLink
									href={"https://snapchat.com/" + item.snapchat}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaSnapchat />
									</IconHolder>
									{!props.mini ? <ItemName>Snapchat</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.soundcloud ? (
							<Item>
								<ItemLink
									href={"https://soundcloud.com/" + item.soundcloud}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaSoundcloud />
									</IconHolder>
									{!props.mini ? <ItemName>SoundCloud</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
						{item.tumblr ? (
							<Item>
								<ItemLink
									href={"https://tumblr.com/" + item.tumblr}
									rel="nofollow"
									target="blank"
									className={props.mini ? miniClasses : bigClasses}
								>
									<IconHolder>
										<FaTumblr />
									</IconHolder>
									{!props.mini ? <ItemName>Tumblr</ItemName> : null}
								</ItemLink>
							</Item>
						) : null}
					</ItemList>
				</SocialNetworkList>
			))}
		</SocialNetworkList>
	);
};

export default SocialNetworks;

// ${props => (props.featured ? "0 0 100%" : "0 0 49%")};
// <Post featured={props.featured}>

// {props =>
//   props.featured ? (
//     <h4>this is featured</h4>
//   ) : (
//     <h2>this is not featured</h2>
//   )
// }

// className={props.mini ? "bg-red" : null}
