/* global tw */
import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import { relatedListing } from "~/queries/listingQuery"; // eslint-disable-line
import { fullContacts } from "~/queries/contactsQuery"; // eslint-disable-line
import Layout from "../components/layout";
import PageWrapper from "../components/Structure/PageWrapper";
// import Heading from "../components/Heading";
import PrimaryContacts from "../components/PrimaryContacts";
import SocialNetworks from "../components/SocialNetworks";
import MapCircle from "../components/Map/MapCircle";
import styled from "styled-components";
import PageHeading from "../components/Heading/PageHeading";
// import { AppBar, Tabs, Tab } from "@material-ui/core";

// import Tabs from "../components/Tabs/Tabs";
import CardSelections from "../components/CardSelections";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SEO from "../components/seo";

const TabsWrapper = styled.div`
	.react-tabs {
		-webkit-tap-highlight-color: transparent;
	}

	.react-tabs__tab-list {
		${tw`font-dincond uppercase font-black text-lg -mx-2 mb-3 p-0`};
	}

	.react-tabs__tab {
		${tw`inline-block mx-2 relative font-dincond uppercase leading-mini text-lg py-2`};
		list-style: none;
		cursor: pointer;
		border-bottom: 4px solid;
	}

	.react-tabs__tab--selected {
		${tw`text-red`};
	}

	.react-tabs__tab--disabled {
		${tw`border border-grey-light border-dashed text-grey cursor-pointer`};
	}

	.react-tabs__tab:focus {
		${tw`border border-grey-light border-dashed`};
		box-shadow: none;
		outline: none;
	}

	.react-tabs__tab-panel {
		${tw`hidden`};
	}

	.react-tabs__tab-panel--selected {
		${tw`block`};
	}
`;

const ContactList = styled.ul`
	${tw`list-reset inline-block`};
`;

const ContactListItem = styled.li`
	${tw`text-md font-dincond uppercase font-black`};
`;

class ListingPost extends Component {
	render() {
		const {
			data: {
				recraft: { post, otherDeals, related, morelistings, evenmorelistings }
			}
		} = this.props;

		const StreetAddress = post.addressSelector.length
			? post.addressSelector[0].title
			: null;

		const SocialNetworkList = () => {
			return <SocialNetworks items={post.socializr} />;
		};

		const PrimaryContactList = () => {
			return (
				<PrimaryContacts
					item={post.primaryContact[0]}
					address={StreetAddress}
				/>
			);
		};

		const RelatedTab =
			post.locationCategory && post.locationCategory.length
				? post.locationCategory[0].title
				: null;

		const RelatedId =
			post.locationCategory && post.locationCategory.length
				? post.locationCategory[0].id
				: null;

		// const NearbyPlacesIds = post.preparseNearbyListingsIds

		// var array = post.preparseNearbyListingsIds.trim();

		// var newArray = array.split(",");

		// {newArray.map((item, i) => (
		// 	<h2 key={i}>{item}</h2>
		// ))}

		return (
			<Layout location={this.props.location}>
				<SEO
					title={post.title}
					keywords={[`dumbo`, `places`, `dumbo businesses`]}
				/>
				<PageWrapper
					imageObject={
						!post.featuredImage || !post.featuredImage.length
							? null
							: post.featuredImage[0]
					}
					mainContent={
						<div>
							<Link
								to="/places"
								className="uppercase text-xs tracking-wide text-grey-dark mb-4 block"
							>
								/ &ensp; Places - {RelatedId}
							</Link>
						</div>
					}
					subtitle={
						<ContactList>
							{StreetAddress ? (
								<ContactListItem>{StreetAddress}</ContactListItem>
							) : null}
						</ContactList>
					}
					title={post.title}
					body={post.body ? post.body.content : null}
					sidebarContent={
						<div>
							<MapCircle post={post} />
							<PrimaryContactList />
							<SocialNetworkList />
						</div>
					}
					bonusContent={
						morelistings.length ? (
							<TabsWrapper>
								<Tabs>
									<TabList>
										<Tab>
											{RelatedTab
												? "More " + RelatedTab
												: "other non related places"}
										</Tab>
										<Tab>Other Places</Tab>
									</TabList>

									<TabPanel>
										<CardSelections post={post} items={morelistings} />
									</TabPanel>
									<TabPanel>
										{evenmorelistings ? (
											<CardSelections post={post} items={evenmorelistings} />
										) : (
											"also also"
										)}
									</TabPanel>
								</Tabs>
							</TabsWrapper>
						) : null
					}
				/>

				<PageHeading post={post} title="hello" />
				{related.length ? <CardSelections post={post} items={related} /> : null}
			</Layout>
		);
	}
}

export default ListingPost;

export const pageQuery = graphql`
	query ListingPostByUri($uri: String!, $id: [Int!]) {
		recraft {
			post: entry(uri: $uri) {
				...relatedListing
				...fullContacts
				... on Recraft_Listings {
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
					locationCategory {
						title
						friendlyName
						id
					}

					preparseNearbyListingsIds
				}
			}
			otherDeals: entriesConnection(id: $id) {
				edges {
					node {
						... on Recraft_Listings {
							title
						}
					}
					relatedEntries(section: deals) {
						edges {
							node {
								... on Recraft_Deals {
									title
									dealName
									dealTerms
								}
							}
						}
					}
				}
			}
			related: entries(relatedTo: [{ element: $id }], section: news) {
				... on Recraft_News {
					title
					id
					uri
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
					newsCategory {
						title
					}
				}
			}

			morelistings: entries(
				section: listings
				limit: 3
				idNot: $id
				orderBy: "title asc"
			) {
				... on Recraft_Listings {
					title
					id
					uri
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
					locationCategory {
						title
						friendlyName
					}
				}
			}

			evenmorelistings: entries(
				section: listings
				limit: 3
				idNot: $id
				orderBy: "title desc"
			) {
				... on Recraft_Listings {
					title
					id
					uri
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
					locationCategory {
						title
						friendlyName
					}
				}
			}
		}
	}
`;

// otherDeals.edges.relatedEntries.edges.node.length

// {otherDeals.map((entry, i) => (
// 	<p>{entry.title}</p>
// ))}

// {otherDeals.entriesConnection.length ? (
// 	<div>
// 		<h4>there are deals here</h4>
// 		<p>{otherDeals.entriesConnection.title}</p>
// 	</div>
// ) : (
// 	<p>no deals</p>
// )}

// {otherDeals.edges[0].relatedEntries.edges.map((node, i) => (
// 	<h2 key={i}>{node.title}</h2>
// ))}

// WORKING
// const theDeal = otherDeals.edges[0].relatedEntries.edges[0].node
// ? otherDeals.edges[0].relatedEntries.edges[0].node
// : null;

// WORKING

// inset={
// 	theDeal ? (
// 		<p className="text-white leading-normal ignoreReading mb-0">
// 			{theDeal.dealName}
// 		</p>
// 	) : null
// }

// <div className="bg-white p-6">
// 					{" "}
// 					{theDeal ? <h2>{theDeal.dealName}</h2> : null}
// 				</div>

// MOSTLY WORKING (for cecconis that is)

// function Greeting(props) {
// 	if (otherDeals !== "undefined" && otherDeals.edges !== null) {
// 		return otherDeals.edges[0].relatedEntries.edges[0].node.title;
// 	}
// 	return "garbage";
// }

// {
//   "uri": "/hometo/cecconis",
//   "id": 507
// }

// inset={<p>the inset</p>}

// <h2>{post.preparseNearbyListingsIds}</h2>

// <div className="bg-white p-8" />
