/* global tw */
import React from "react";
import styled from "styled-components";
import {
	FaPhone,
	FaMousePointer,
	FaEnvelope,
	FaMapMarkerAlt
} from "react-icons/fa";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const PrimaryContactList = styled.div`
	${tw`block`};
`;

const ItemList = styled.ul`
	${tw`list-reset`};
`;

const Item = styled.li`
	${tw`flex justify-center`};
	transition: 0.4s all;
	transform: scale(1);
	&:hover {
		transform: scale(1);

		span {
			transform: scale(1.4);
		}
	}
`;

// .side-actioner ul li:hover [class^="icon-"], .side-actioner ul li:hover [class*=" icon-"]

const IconHolder = styled.span`
	${tw`ml-1`};
	transform: scale(1);
	transition: 0.4s all;
`;

// const Item = styled.li`
// 	${tw`text-2xl uppercase font-dincond font-bold m-0 overflow-x-hidden pt-3 pr-0 pb-2 pl-0 border-b border-black flex items-center`};
// `;

// const MiniItem = styled.li`
// 	${tw`text-2xl uppercase font-dincond font-bold m-0 overflow-x-hidden`};
// `;

const ItemLink = styled.a`
	${tw`uppercase text-black`};
`;
// font-size: ${props => (props.featured ? "3rem" : ".5rem")};

const ItemName = styled.p`
	${tw`pl-4 text-cb-red mb-0`};
`;

const bigClasses =
	"text-2xl uppercase font-dincond font-bold m-0 pt-3 pr-0 pb-2 pl-0 border-b border-black flex items-center w-full";

const miniClasses =
	"text-xl uppercase inline-flex leading-none pr-3 items-center";

const PrimaryContacts = ({
	item,
	address,
	sizing,
	featured,
	carpet,
	...props
}) => {
	return (
		<PrimaryContactList>
			<ItemList className={props.mini ? "my-3 flex justify-start" : null}>
				{address ? (
					<Item featured={props.featured}>
						<ItemLink
							href={"#"}
							target="blank"
							className={props.mini ? miniClasses : bigClasses}
						>
							<IconHolder>
								<FaMapMarkerAlt />
							</IconHolder>
							{!props.mini ? <ItemName>{address}</ItemName> : null}
						</ItemLink>
					</Item>
				) : null}
				{item.website ? (
					<Item featured={props.featured}>
						<OutboundLink
							href={item.website + "?ref=dumbobid"}
							rel="nofollow"
							target="blank"
							className={
								props.mini
									? miniClasses + " text-black"
									: bigClasses + " text-black"
							}
						>
							<IconHolder>
								<FaMousePointer />
							</IconHolder>
							{!props.mini ? <ItemName>Website</ItemName> : null}
						</OutboundLink>
					</Item>
				) : null}
				{item.phone ? (
					<Item>
						<ItemLink
							href={"tel:" + item.phone}
							itemProp="telephone"
							className={props.mini ? miniClasses : bigClasses}
						>
							<IconHolder>
								<FaPhone />
							</IconHolder>
							{!props.mini ? <ItemName>Phone</ItemName> : null}
						</ItemLink>
					</Item>
				) : null}
				{item.emailAddress ? (
					<Item featured={props.featured}>
						<ItemLink
							href={"mailto:" + item.emailAddress}
							className={props.mini ? miniClasses : bigClasses}
						>
							<IconHolder>
								<FaEnvelope />
							</IconHolder>
							{!props.mini ? <ItemName>Email</ItemName> : null}
						</ItemLink>
					</Item>
				) : null}
			</ItemList>
		</PrimaryContactList>
	);
};

export default PrimaryContacts;
