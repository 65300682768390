/* global tw */
import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import TextTruncate from "react-text-truncate";
import MicroImage from "../ImageAtom";

// const CardBlock = styled.div`
// 	${tw`w-full md:w-1/3 flex px-4 mb-6 group text-black trans`};
// `;

const CardBlock = styled.div`
	${tw`w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex sm:px-3 md:mb-6 group text-black trans`};
`;

const CardBg = styled.div`
	${tw`card bg-white trans overflow-hidden w-full h-full`};
`;

const SecondLine = styled.p`
	${tw`text-xs leading-tight font-dincond uppercase font-black`};
`;

const CatCrumb = styled.h3`
	${tw`text-xs uppercase mb-1 text-red hover:text-red-darker`};
`;

const Card = ({
	title,
	mainLink,
	imageObject,
	imageUrl,
	tinyImageUrl,
	placeholderUrl,
	lineTwo,
	catName,
	extraStuff,
	noBorder,
	...props
}) => {
	return (
		<CardBlock className={props.avatarStyle ? "mb-0" : "mb-6"}>
			<CardBg
				className={
					props.noBorder
						? "is-bw-img hover-color transition-duration-300 transition-timing-ease-in group-hover:shadow-lg"
						: "is-bw-img hover-color transition-duration-300 transition-timing-ease-in group-hover:shadow-lg border-b-3 border-red"
				}
			>
				<Link to={mainLink}>
					<div className={props.avatarStyle ? "hidden md:block w-full" : ""}>
						<MicroImage
							imageObject={imageObject}
							title={title}
							avatarStyle={props.avatarStyle}
							aspectRatio={"16x9"}
							placeholder={"https://placehold.it/500x281"}
							imgixParams={"?w=500&h=281&fit=crop"}
							imageClasses={
								props.avatarStyle
									? "hidden md:block w-full h-auto"
									: "w-full is-bw hover-color h-auto"
							}
							lazyClasses={"aspect-ratio-16/9"}
						/>
					</div>
				</Link>

				<div className="px-6 py-4 flex items-center w-full">
					{props.avatarStyle ? (
						<Link to={mainLink}>
							<div className={"flex md:hidden w-24 h-24 relative mr-4"}>
								<MicroImage
									imageObject={imageObject}
									title={title}
									avatarStyle={true}
									placeholder={"https://placehold.it/73x73"}
									imgixParams={"?w=73&h=73&fit=crop&crop=entropy"}
									imageClasses={"w-16 h-16 rounded-full mr-4 ml-0"}
									lazyClasses={"aspect-ratio-square"}
								/>
							</div>
						</Link>
					) : null}
					<div className="sm:flex-grow">
						<CatCrumb>{catName}</CatCrumb>

						<h2 className="text-2xl leading-none font-dincond uppercase font-black">
							<Link
								to={mainLink}
								className="text-black hover:text-red transition-duration-300 transition-timing-ease-in"
							>
								<TextTruncate line={2} truncateText="…" text={title} />
							</Link>
						</h2>

						<SecondLine>{lineTwo}</SecondLine>
						{extraStuff}
						{props.children}
					</div>
				</div>
			</CardBg>
		</CardBlock>
	);
};
export default Card;

// import { LazyLoadImage } from "react-lazy-load-image-component";
// import LazyLoad from "react-lazy-load";
