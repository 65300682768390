/* global tw */
import React from "react";
import styled from "styled-components";
import mapMarkerUrl, {
	ReactComponent as MapMarker
} from "~/components/Icons/dumbo-marker.svg";

const MapLink = styled.a`
	${tw`block`};
`;

const MarkerWrapper = styled.span`
	${tw`absolute leading-none z-10`};
	left: calc(50% - 32px);
	top: calc(50% - 32px);
	transform: scale(1);
	transition: 0.4s all;
	height: 64px;
	font-size: 64px;
`;

const MapCircle = ({ post, ...props }) => {
	const MapUrl =
		post.addressSelector &&
		post.addressSelector.length &&
		post.addressSelector[0].uberAddress.lat
			? "https://www.google.com/maps/search/?api=1&query=" +
			  post.addressSelector[0].uberAddress.lat +
			  "," +
			  post.addressSelector[0].uberAddress.lng
			: null;
	const MapImage =
		post.addressSelector &&
		post.addressSelector.length &&
		post.addressSelector[0].uberAddress.lat
			? "https://api.mapbox.com/styles/v1/smartassdesign/cj22bd1xr00022st2r6rdccfe/static/" +
			  post.addressSelector[0].uberAddress.lng +
			  "," +
			  post.addressSelector[0].uberAddress.lat +
			  ",16.00,0,0/300x300?access_token=pk.eyJ1Ijoic21hcnRhc3NkZXNpZ24iLCJhIjoiY2ltcHBhMm5jMDA2NXdibHVrZzI3YTdpciJ9.GVLfDKnH2xdGYlwsz6hWlw"
			: null;

	return MapImage ? (
		<MapLink href={MapUrl} target="_blank">
			<div className="static-pin is-bw hover-color relative marker-grower">
				<MarkerWrapper>
					<MapMarker />
				</MarkerWrapper>
				<img
					src={MapImage}
					className="img-fluid rounded-full border-4 border-black text-black block mx-auto"
					alt={"Map of " + post.title}
				/>
			</div>
		</MapLink>
	) : null;
};

export default MapCircle;

// <img src={mapMarkerUrl} alt="star" />
